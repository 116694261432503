import React, { useState } from 'react';
import { useSiteState } from '../../lib/navContext';
import { GuidesForm } from '../forms/guidesForm';

const SignupWindow = ({ setToggleSignup, setAccessGuides }) => {
  return (
    <div className="absolute inset-0 z-20 bg-black text-white rounded-md h-full p-6 xl:p-12 flex flex-col items-start">
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col justify-between md:h-1/2 border-b border-current">
          <div className="font-medium text-bc-green">Resources</div>
          <div>
            <div className="text-h3 font-medium leading-heading mt-16 mb-2 lg:mt-4 xl:pb-12 2xl:pb-16 3xl:pb-20 pb-6">
              Sign up for exclusive access
            </div>
          </div>
        </div>
        <div className="md:h-1/2">
          <GuidesForm setToggleSignup={setToggleSignup} setAccessGuides={setAccessGuides} />
        </div>
      </div>
    </div>
  );
};

export const FeaturedGuides = ({ guides }) => {
  const { accessGuides, setAccessGuides } = useSiteState();
  const [toggleSignup, setToggleSignup] = useState(false); // eslint-disable-line
  return (
    <div className="col-span-1 h-full bg-white p-6 lg:p-12 rounded-md flex flex-col items-start relative">
      {toggleSignup && (
        <SignupWindow setToggleSignup={setToggleSignup} setAccessGuides={setAccessGuides} />
      )}
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="font-medium text-bg-darkAlt">Checklists</div>
        </div>
        <div>
          <div className="text-h3 font-medium leading-heading mt-16 mb-2 lg:mt-4 xl:pb-12 2xl:pb-16 3xl:pb-20 pb-6 border-b border-doc-text">
            Small Business Guides
          </div>
          <ul className="flex flex-col gap-y-1 relative">
            <>
              {!accessGuides && (
                <div
                  aria-label="Show signup form for guide access"
                  role="button"
                  tabIndex={0}
                  onKeyDown={setToggleSignup}
                  onClick={setToggleSignup}
                  className="absolute top-0 bottom-0 left-0 right-0 z-10 cursor-pointer transition-all duration-500 hover:bg-white hover:opacity-80 filter hover:blur-lg"
                ></div>
              )}
              {guides.guides.map((item, index) => {
                return (
                  <li>
                    {item.title && item.url && (
                      <a
                        className="flex justify-between gap-x-4 items-baseline hover:underline"
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.title}
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1 12L12 1M12 1H1M12 1V12" stroke="black" />
                        </svg>
                      </a>
                    )}
                  </li>
                );
              })}
            </>
          </ul>
        </div>
      </div>
    </div>
  );
};;
