import React from 'react';
import { Link } from 'gatsby';
import { PortableTextBlock } from '../sanity/portableTextBlock';

export const FeaturedPost = ({ postImage, category, title, _rawPostExcerpt, slug, postDate }) => {
  return (
    <Link
      to={`/insights/${slug && slug.current}`}
      className="bg-white p-6 md:p-12 rounded-md flex flex-col items-start md:grid md:grid-cols-7 md:gap-x-8"
    >
      <div className="md:col-start-5 md:col-end-8 md:row-start-1 md:flex md:flex-col md:justify-between md:h-full">
        <div>
          <div className="text-bg-darkAlt font-medium">{postDate}</div>
          <div className="font-medium">{category.title}</div>
        </div>
        <div>
          <div className="text-h3 font-medium leading-heading my-6">{title}</div>
          {_rawPostExcerpt && <PortableTextBlock text={_rawPostExcerpt} />}
          <span className="flex items-baseline gap-2 font-medium mt-4 mb-6 md:mb-0 text-bg-darkAlt">
            Read More
          </span>
        </div>
      </div>
      {postImage && (
        <div className="relative w-full md:col-start-1 md:col-end-5 aspect-w-7 aspect-h-10 block">
          <img className="object-cover absolute inset-0" src={postImage.asset.url} alt={title} />
        </div>
      )}
    </Link>
  );
};
