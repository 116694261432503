import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation } from '@reach/router';

// SVG
import ArrowRight from '../../svg/arrow-r.svg';


function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export const GuidesForm = ({ setAccessGuides, setToggleSignup }) => {
  const { pathname } = useLocation();
  return (
    <div>
      {/* Form */}
      <Formik
        initialValues={{
          email: '',
        }}
        validate={(values) => {
          const errors = {};

          if (!values.email) {
            errors.email = 'Email address is Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          fetch(pathname || '/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': `contact-form`,
              ...values,
            }),
          })
            .then(() => {
              setToggleSignup(false);
              setAccessGuides(true);
              setSubmitting(false);
              setStatus('success');
            })
            .catch((error) => alert(error));
        }}
      >
        {({
          errors,
          status,
          values,
          /* and other goodies */
        }) => (
          <Form className="w-full relative">
            <div className="relative">
              <Field
                type="text"
                name="name"
                placeholder={'Name'}
                className={`outline-none rounded-none font-display appearance-none bg-transparent w-full border-t border-b border-current text-current py-3 placeholder-current`}
              />
              <ErrorMessage
                name="email"
                className="absolute top-full pt-2 text-sm py-2 w-full"
                component="div"
              />
              <Field
                type="text"
                name="email"
                placeholder={'Your Email'}
                className={`outline-none rounded-none font-display appearance-none bg-transparent w-full border-b border-current text-current py-3 placeholder-current`}
              />
              <ErrorMessage
                name="email"
                className="absolute top-full pt-2 text-sm py-2 w-full"
                component="div"
              />
              <div className="w-full cursor-pointer border-b border-black group">
                <button
                  className={`w-full flex transition-all duration-500 justify-between items-center py-4 text-bc-green group-hover:text-white ${
                    values.email ? '' : 'pointer-events-none'
                  }`}
                  type="submit"
                >
                  Get Access
                  <ArrowRight className="w-6 text-current fill-current" />
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
