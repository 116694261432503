import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Marquee from 'react-fast-marquee';

import { useSiteState } from '../lib/navContext';
import { PageMeta } from '../components/pageMeta';
import { FeaturedPost } from '../components/blog/featuredPost';
import { PostCard } from '../components/blog/postCard';
import { FeaturedGuides } from '../components/blog/featuredGuides';
import Layout from '../components/layout';
import { BlogHeader } from '../components/pageModules/modules';

const InsightsPage = ({ data: { featuredPost, allPosts, categories, testimonials, guides, showCats } }) => {
  const { homeHeader, setHomeHeader } = useSiteState();

  useEffect(() => {
    if (homeHeader === true) {
      setHomeHeader(false);
    }
    document.documentElement.style.setProperty(`--docBg`, `#EAE9E7`);
    document.documentElement.style.setProperty(`--docText`, `#000`);
    document.documentElement.style.setProperty(`--docNavActive`, `#A7A9AB`);
  }, [setHomeHeader, homeHeader]);

  return (
    <Layout>
      <PageMeta metaTitle="Insights" />
      {/* Marquee */}
      <div className="w-full bg-white text-black card-box-shadow mt-8 lg:mt-20">
        <Marquee gradient={false} speed={40}>
          {testimonials.testimonials.map((testimonial) => {
            const { text } = testimonial;
            return <div className="py-4 px-8 lg:px-12 lg:py-4 border-r border-bg-grey">{text}</div>;
          })}
        </Marquee>
      </div>
      <BlogHeader currentCategory={`All Posts`} showCats={showCats} categories={categories.edges} />
      <div className="grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3 md:gap-24 px-gutter pb-24 lg:pb-40">
        {/* Featured Post */}
        <div className="col-span-1 md:col-span-2">
          <FeaturedPost {...featuredPost.edges[0].node} />
        </div>
        <FeaturedGuides guides={guides} />
        {/* All Posts */}
        {allPosts.edges.map(({ node }) => (
          <PostCard {...node} />
        ))}
      </div>
    </Layout>
  );
};

export default InsightsPage;

export const pageQuery = graphql`
  query blogPortalQuery {
    featuredPost: allSanityBlogPost(sort: { fields: postDate, order: DESC }, limit: 1) {
      edges {
        node {
          title
          _rawPostExcerpt(resolveReferences: { maxDepth: 10 })
          postImage {
            asset {
              url
            }
          }
          category {
            title
          }
          postDate(formatString: "DD MMMM YYYY")
          slug {
            current
          }
        }
      }
    }
    categories: allSanityBlogCategory {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    showCats: sanityPageBlog {
      showCategories
    }
    testimonials: sanityPageBlog {
      testimonials {
        name
        text
        company
        photo {
          asset {
            url
          }
        }
      }
    }
    guides: sanityPageBlog {
      guides {
        title
        url
      }
    }
    allPosts: allSanityBlogPost(sort: { fields: postDate, order: DESC }, skip: 1) {
      edges {
        node {
          title
          _rawPostExcerpt(resolveReferences: { maxDepth: 10 })
          postImage {
            asset {
              url
            }
          }
          category {
            title
          }
          postDate(formatString: "DD MMMM YYYY")
          slug {
            current
          }
        }
      }
    }
  }
`;
