import React from 'react';
import { Link } from 'gatsby';

export const PostCard = ({ postImage, title, category, postExcerpt, slug, postDate }) => {
  return (
    <Link to={`/insights/${slug && slug.current}`} className="">
      <div className="text-bg-darkAlt font-medium">{postDate}</div>
      <div className="font-medium mb-4">{category.title}</div>
      {postImage && (
        <div className="relative w-full aspect-w-10 aspect-h-10 block">
          <img className="object-cover absolute inset-0" src={postImage.asset.url} alt={title} />
        </div>
      )}
      <div className="">
        <div className="">
          <div className="text-h3 font-medium leading-heading my-6">{title}</div>
          <span className="flex items-baseline gap-2 font-medium mb-8 text-bg-darkAlt">
            Read More
          </span>
        </div>
      </div>
    </Link>
  );
};
